<!--
File: UnitEditForm.vue
Description: form for adding/editing a single measurement unit.
-->
<template>
  <modal v-if="eligible" :title="screenTitle" :form="formName" :errCount="errors.count()"
    :hasUnsavedChanges="hasUnsavedChanges" @close="$emit('close')" @save="validate">

    <template slot='body'>
      <MultilanguageEditForm ref="dynamicForm" v-model="formData" :fields="nameFields"
        :validations="validations" />
    </template>
  </modal>
</template>
<script>
  import { mapActions } from 'vuex'
  import { Modal, MultilanguageEditForm } from '@/pages/Components'
  import permissions from "@/mixins/permissionsMixin"
  import { savedMessage } from '@/mixins/messagesMixin'

  export default {
    name: 'unit-edit-form',
    mixins: [permissions],

    data() {
      return {
        formName: 'UnitEditForm',
        eligible: false,

        formData: {
          description_en: null,
          description_ru: null,
          description_kg: null,
        },

        nameFields: [
          { name: 'description_en', label: 'stdCols.name_en' },
          { name: 'description_ru', label: 'stdCols.name_ru' },
          { name: 'description_kg', label: 'stdCols.name_kg' },
        ],

        validations: {
          description_en: { required: true, min: 1 },
          description_ru: { required: true, min: 1 },
          description_kg: { required: true, min: 1 },
        },

        initialFormState: null,
      }
    },

    props: {
      itemId: null
    },

    components: {
      Modal,
      MultilanguageEditForm
    },

    async mounted() {
      // Check if we are eligible to view the form
      this.eligible = await this.checkIfScreenAllowed();
      if (!this.eligible) {
        this.$emit('close');
        return;
      };

      if (this.itemId) { // Load data 
        this.formData = await this.loadItem(this.itemId);
      }

      this.$nextTick(async () => {
        this.initialFormState = this.getCurrentState;
        await this.$refs.dynamicForm.validateForm();
      });
    },

    methods: {
      ...mapActions({
        loadItem: 'ReferenceData/LOAD_UNIT_BY_ID',
        addUnit: 'ReferenceData/ADD_NEW_UNIT',
        editUnit: 'ReferenceData/UPDATE_UNIT',
        highlightRow: 'HIGHLIGHT_ROW'
      }),

      async validate() {
        const isValid = await this.$refs.dynamicForm.validateForm();
        if (!isValid) return;

        const theItem = this.getCurrentState;

        let errDesc = '';
        let newId;
        const action = !this.itemId ? this.addUnit : this.editUnit;
        const payload = !this.itemId ? theItem : { id: this.itemId, theItem };
        try {
          const res = await action(payload);
          newId = res?.id;
        } catch (err) {
          errDesc = err.message || this.$t('messages.unknown_error');
        }

        this.$nextTick(() => this.$validator.reset());
        this.$emit('close');
        await savedMessage(errDesc, this.$t('label.units'), this.formData.description_ru, this.formData.description_en);
        this.highlightRow(newId);
      },
    },

    computed: {
      screenTitle() {
        return this.itemId ? this.$t('screen_titles.unit_upd') : this.$t('screen_titles.unit_add');
      },

      getCurrentState() {
        return {
          description_en: this.formData.description_en,
          description_ru: this.formData.description_ru,
          description_kg: this.formData.description_kg
        }
      },

      hasUnsavedChanges() {
        if (!this.initialFormState) return false;

        return this.formData.description_en !== this.initialFormState.description_en ||
          this.formData.description_ru !== this.initialFormState.description_ru ||
          this.formData.description_kg !== this.initialFormState.description_kg
      }
    }
  }
</script>
<style>
.modal-container {
  max-width: 500px;
}
</style>
